














































































































































































































































































































































































































import Vue from "vue";
import Errors from "../PdfSign/Errors.vue";
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";

import { mapActions, mapGetters, mapState } from "vuex";
import City from "@/models/City";
import { getAPI } from "@/api/axios-base";
import store from "@/store/index";
import cleanObject from "@/utils/cleanObject";
import rules from "@/components/account/rules";
import isEqual from "lodash/isEqual";
import { AxiosError } from "axios";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import Worker from "@/models/Worker";

export default Vue.extend({
  components: { Errors, MaDatePicker },
  name: "form-update-patient",
  data() {
    return {
      formValid: false,
      birthDateMenu: false,
      rules: {
        required: rules.required,
        email: rules.email,
        zip: rules.isZipCode,
        phone: rules.isPhone,
        phone1:rules.isPhone1
      },
      stateCities: [],

      loadingCities: false,
      state: 0,
      doctor: null,

      gender: "male",
      loading: false,
      showErrors: false,
      errorMessage: "",
      langs: [
        {
          label: "English",
          value: "ENGLISH",
        },
        {
          label: "Spanish",
          value: "SPANISH",
        },
      ],
      form: {
        contactPreference: "PHONE",
        firstName: "",
        lastName: "",
        midleName: "",

        patientDoctor: "",
        sellerUuid: "",
        mailingCity: -1,
        mailingAddress: "",
        mailingZip: "",
        birthDate: "",
        medicalLocation: "",
        howKnow: "",
        homePhone: "",
        fullname: "",
        preferLanguage: "ENGLISH",
        isMale: true,
        emergencyContact: "",
        emergencyPhone: "",
        emergencyRelation: "",
        email: "",
        weight: 0,
        height: 0,
        heightInches: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["getCoordinatorN", "getSellerN", "getpatientN"]),
    ...mapState("crmMedicFormModule", [
      "states",
      "loadingStates",
      "loadingPatiens",
      "loadingDoctors",
      "medicalLocations",
      "patients",
      "patientDetail",
    ]),
    ...mapState(["profile"]),
    ...mapState("crmEmployeeModule", ["agents", "workers", "coordinators"]),
    ...mapState("crmSettingsModule", ["loadingRefer", "references"]),

    _seller() {
      let arrayseller: any = [];
      if (this.workers.length != 0) {
        arrayseller = this.workers.filter((s: Worker) => s.rol != "SUPER");
      }
      return arrayseller;
    },
  },
  watch: {
    state: function (stateId: number): void {
      if (stateId == undefined || stateId == null) {
        this.stateCities = [];
        this.loadingCities = false;

        return;
      }

      this.loadingCities = true;
      this.actGetCityByState(stateId).then((cities: City[]) => {
        this.loadingCities = false;
        (this.stateCities as any) = cities;
      });
    },
  },
  async mounted() {
    this.loading = true;
    this.gender = "male";
    await this.getPatientData();
    await this.actListStates();
    await this.actGetEmployees();
    await this.actListReferences();

    await this.actGetMedicalLocations();

    this.loading = false;
  },
  methods: {
    ...mapActions("crmMedicFormModule", [
      "actListStates",
      "actGetCityByState",

      "actGetMedicalLocations",
    ]),
    ...mapActions("crmEmployeeModule", [
      "actGetEmployees",
      "actGetCoordinators",
    ]),
    ...mapActions("crmSettingsModule", ["actListReferences"]),
    update(): void {
      const { form: body } = this;
      let { ...rest } = body;
      const { birthDate } = body;
      let ISObirdDate = "";
      if (body.birthDate) {
        ISObirdDate = new Date(birthDate).toISOString();
      }
      if (rest.homePhone != "" && rest.homePhone != null) {
        (rest.homePhone as any) = "+1" + rest.homePhone;
      }
      if (rest.emergencyPhone != "" && rest.emergencyPhone != null) {
        (rest.emergencyPhone as any) = "+1" + rest.emergencyPhone;
      }

      if (rest.patientDoctor == "") {
        delete (rest as any).patientDoctor;
      }
      if (rest.mailingAddress == "") {
        delete (rest as any).mailingAddress;
      }
      if (rest.mailingCity == -1) {
        delete (rest as any).mailingCity;
      }
      if (rest.emergencyPhone == "" || rest.emergencyPhone == null) {
        delete (rest as any).emergencyPhone;
      }
      if (rest.mailingZip == "" || rest.mailingZip == null) {
        delete (rest as any).mailingZip;
      }
      if (rest.sellerUuid == "") {
        delete (rest as any).sellerUuid;
      }

      this.loading = true;
      // Remove empty values
      rest = cleanObject({ ...rest, birthDate: ISObirdDate });
      if (isEqual(rest, { isMale: false })) {
        this.loading = false;
        notifyInfo("To send changes you must have to change some field");
        return;
      }
      (rest.fullname as any) =
        rest.firstName +
        (rest.midleName ? " " + rest.midleName + " " : " ") +
        rest.lastName;
      getAPI
        .put(`/patient/updatePatient/${this.$route.params.uuid}`, {
          ...rest,
        })
        .then(() => {
          notifySuccess(this.$t("medicForm.notificationPatientUpdated"));
          this.$refs.patientForm.reset();
          this.loading = false;
          this.$router.push({
            name: "Patient ddetails",
            params: { uuid: this.$route.params.uuid },
          });
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    async getPatientData(): Promise<any> {
      let patient;
      if (this.patientDetail == null) {
        patient = (
          await getAPI(`/patient/getPatient/${this.$route.params.uuid}`)
        ).data;
      } else {
        patient = this.patientDetail;
      }

      this.form.firstName = patient?.firstName;
      this.form.lastName = patient?.lastName;
      this.form.contactPreference = patient?.contactPreference;
      this.form.midleName = patient?.midleName;
      this.form.preferLanguage = patient?.preferLanguage;
      this.form.mailingAddress = patient?.mailingAddress;
      this.form.mailingZip = patient?.mailingZip;
      this.form.mailingCity = patient?.mailingCity ? patient.mailingCity : 3319;
      this.form.birthDate = patient?.birthDate;
      this.form.howKnow = patient?.howKnow;
      this.form.medicalLocation = patient.medicalLocation
        ? patient?.medicalLocation.uuid
        : "";
      this.form.homePhone = patient?.homePhone.replace("+1", "");
      this.form.emergencyContact = patient?.emergencyContact;
      this.form.emergencyRelation = patient?.emergencyRelation;
      this.form.emergencyPhone = patient?.emergencyPhone
        ? patient?.emergencyPhone.replace("+1", "")
        : "";
      this.form.email = patient?.email;
      this.form.isMale = patient?.isMale;
      this.form.height = patient?.height;
      this.form.weight = patient?.weight;
      this.form.heightInches = patient?.heightInches;
      this.form.patientDoctor =
        patient?.patientDoctor != null ? patient.patientDoctor.uuid : "";
      this.form.sellerUuid = patient?.seller != null ? patient.seller.uuid : "";
      this.gender = patient?.isMale ? "male" : "female";

      this.doctor = patient?.patientDoctor != null ? patient.patientDoctor : "";
      const stateId = patient.mailingCity ? patient?.mailingCity.state.id : 23;

      this.state = stateId;
      // (this as any).form.patientDoctor = doctor?.uuid;
      this.loadingCities = true;
      const cities = await this.actGetCityByState(stateId);
      (this.stateCities as any) = cities;
      (this.form as any).mailingCity = patient.mailingCity
        ? patient?.mailingCity.id
        : 0;
      (this.form as any).residentialCity =
        patient?.residentialCity != null ? patient?.residentialCity.id : null;
      this.loadingCities = false;
    },
    reset(): void {
      this.$refs.patientForm.reset();
    },
    cancel() {
      this.$refs.patientForm.reset();
      this.loading = false;
      this.$router.push({
        name: "Patient ddetails",
        params: { uuid: this.$route.params.uuid },
      });
    },
  },
});
